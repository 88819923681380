/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React from 'react';
import { Box, Center, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { PreciselyPropertyAttributes } from '../types';
import { Loader } from '../../../components';
import { StatsItemWithIcon } from '../../../components/Informations/StatsItemWithIcon';
import {
	BathIcon,
	BedIcon,
	DocumentScanIcon,
	FireIcon,
	IconBuilding,
	MoneyOnHandIcon,
	RulerIcon,
	SaleIcon,
	TaxIcon,
} from '../../../assets';
import { formatDollars } from '../../../utils';

interface IPropertyAttributesProps {
	loading: boolean;
	data?: PreciselyPropertyAttributes | null;
}

export const PropertyAttributes: React.FC<IPropertyAttributesProps> = ({
	data,
	loading,
}) => {
	return (
		<Box maxW={'954px'}>
			<Text
				fontSize="large"
				color={'blue.200'}
				fontWeight={700}
				mb="16px">
				Main Property Info
			</Text>
			{loading ? (
				<Center h={'325px'} bg={'white'} borderRadius={'10px'}>
					<Loader spinnerSize="md" />
				</Center>
			) : (
				<Flex
					bg={'white'}
					borderRadius={'10px'}
					flexDirection={'column'}
					gap={'20px'}
					p={'20px'}>
					<SimpleGrid
						minChildWidth={'120px'}
						columnGap={'10px'}
						rowGap={'20px'}
						alignItems={'center'}>
						<StatsItemWithIcon
							label="Bedrooms"
							value={data?.bedrooms || '-'}
							icon={<BedIcon />}
						/>
						<StatsItemWithIcon
							label="Bath"
							value={data?.baths || '-'}
							icon={<BathIcon />}
						/>
						<StatsItemWithIcon
							label="Property APN"
							value={data?.propApn || '-'}
							icon={<DocumentScanIcon />}
						/>
					</SimpleGrid>
					<Divider />
					<SimpleGrid
						minChildWidth={'120px'}
						columnGap={'10px'}
						rowGap={'20px'}
						alignItems={'center'}>
						<StatsItemWithIcon
							label="Property Square Feet"
							value={data?.propSqFt || '-'}
							icon={<RulerIcon />}
						/>
						<StatsItemWithIcon
							label="Building Square Feet"
							value={data?.buildgSqFt || '-'}
							icon={<RulerIcon />}
						/>
						<StatsItemWithIcon
							label="Year Built"
							value={data?.builtYear || '-'}
							icon={<IconBuilding />}
						/>
					</SimpleGrid>

					<Divider />

					<SimpleGrid
						minChildWidth={'120px'}
						columnGap={'10px'}
						rowGap={'20px'}
						alignItems={'center'}>
						<StatsItemWithIcon
							label="Fireplace"
							value={data?.firePlace || '-'}
							icon={<FireIcon />}
						/>
						<StatsItemWithIcon
							label="Assessed Value"
							value={
								data?.assessedValue
									? formatDollars.format(
											+data?.assessedValue || 0,
									  )
									: '-'
							}
							icon={<MoneyOnHandIcon />}
						/>
						<StatsItemWithIcon
							label="Market Value"
							value={
								data?.marketValue
									? formatDollars.format(
											+data?.marketValue || 0,
									  )
									: '-'
							}
							icon={<MoneyOnHandIcon />}
						/>
					</SimpleGrid>

					<Divider />

					<SimpleGrid
						minChildWidth={'120px'}
						columnGap={'10px'}
						rowGap={'20px'}
						alignItems={'center'}>
						<StatsItemWithIcon
							label="Tax Amount"
							value={
								data?.taxAmount
									? formatDollars.format(+data.taxAmount || 0)
									: '-'
							}
							icon={<TaxIcon />}
						/>
						<StatsItemWithIcon
							label="Last Sale"
							value={data?.priorSaleDate || '-'}
							icon={<SaleIcon />}
						/>
						<StatsItemWithIcon
							label="Tax Rate Code"
							value={data?.taxRateCode || '-'}
							icon={<TaxIcon />}
						/>
					</SimpleGrid>
				</Flex>
			)}
		</Box>
	);
};

// <Tbody>
//
// 	<Tr>
// 		<Td fontWeight={600}>Tax Amount</Td>
// 		<Td>
// 			{data?.taxAmount
// 				? formatDollars.format(+data?.taxAmount)
// 				: ''}
// 		</Td>
// 	</Tr>
// 	<Tr>
// 		<Td fontWeight={600}>Last Sale</Td>
// 		<Td>{data?.priorSaleDate || ''}</Td>
// 	</Tr>
// 	<Tr>
// 		<Td fontWeight={600}>Tax Rate Code</Td>
// 		<Td>{data?.taxRateCode || ''}</Td>
// 	</Tr>
// </Tbody>

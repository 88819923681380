// /* eslint-disable no-mixed-spaces-and-tabs */
// /* eslint-disable indent */
import React, {
	useEffect,
	useMemo,
	useState,
	Dispatch,
	SetStateAction,
	useRef,
	useCallback,
} from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useChannel } from 'ably/react';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { Message } from 'ably';
import { MessageWithAddons, DateDivider } from '../../messages-components';
import {
	EditMessageDisplayData,
	ReplyMessage,
	UserByIdData,
	ChatType,
	MessageReactionType,
	MessageReactionActionType,
	JumpToPresentConditionEnum,
	NewMessageDataEventResponse,
	ConversationHistoryItem,
} from '../../types';
import { DeleteMessageConfirmModal } from '../../modals';
import { StartOfDirectChat } from './StartOfDirectChat';
import { Loader } from '../../../../components';
import { useChatUserStore, useMessageNavigationStore } from '../../../../store';
import { deletePrivateMessage, updatePrivateMessage } from '../../helpers';
import { DEFAULT_LAST_READ_TIME, SOCKET_EVENTS } from '../../constants';
import {
	useCreateDirectMessageReaction,
	useDeleteDirectMessageReaction,
	useFindConversationMessage,
	useGetDirectMessages,
	useUpdateDirectMessageReaction,
} from '../../queries';
import { IChatUser } from '../../../../types';
import { updateDirectUserSelfReactions } from '../../utils';
import { useUpdateReactionsList } from '../../hooks';
import { MessengerQueryKeys } from '../../queries/query-keys';
import {
	useHandleDirectMessagesSocketEvents,
	useHandlePrivateMessageDeletion,
} from '../hooks';
import { Alerter, filterAblyErrorsToSentry, wait } from '../../../../utils';

interface IDirectMessagesListProps {
	conversationId?: string | null;
	onSetReplyMessage: (obj: ReplyMessage) => void;
	replyMessage: ReplyMessage | null;
	receiver: UserByIdData | null;
	lastReadTime?: string;
	user: IChatUser | null;
	receiverId?: string;
	updateLastReadTime: (time: string) => void;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	receiverLoading: boolean;
	deleteConversationHandler: VoidFunction;
	setFindLoading: (value: boolean) => void;
}

export const DirectMessagesList: React.FC<IDirectMessagesListProps> = ({
	conversationId,
	onSetReplyMessage,
	receiver,
	replyMessage,
	receiverId,
	lastReadTime,
	updateLastReadTime,
	updateUnreadCount,
	receiverLoading,
	deleteConversationHandler,
	setFindLoading,
}) => {
	const queryClient = useQueryClient();
	const [searchParams, setSearchParams] = useSearchParams();

	const { user } = useChatUserStore();
	const {
		jumpToPresentCondition,
		forcedLoading,
		setJumpToPresentCondition,
		messageAnchorId,
		setMessageAnchorId,
		setForcedLoading,
	} = useMessageNavigationStore();

	const [highlightedId, setHighlightedId] = useState<number | null>(null);
	const [transitionLoading, setTransitionLoading] = useState(false);
	const [messageToDelete, setMessageToDelete] =
		useState<EditMessageDisplayData | null>(null);

	const targetMessageId = searchParams.get('targetMessageId');

	const { mutateAsync: createDirectMessageReaction } =
		useCreateDirectMessageReaction();
	const { mutateAsync: updateDirectMessageReaction } =
		useUpdateDirectMessageReaction();
	const { mutateAsync: removeDirectMessageReaction } =
		useDeleteDirectMessageReaction();
	const { mutate: updateMessageReactionsList } = useUpdateReactionsList(
		MessengerQueryKeys.GET_DIRECT_MESSAGE_REACTIONS,
	);
	const handlePrivateMessageDelete = useHandlePrivateMessageDeletion();

	const bottomInView = useInView(); // scroll down
	const topInView = useInView(); // scroll up
	const parentRef = useRef<HTMLDivElement>(null);
	const currentFetchDirectionRef = useRef('next');
	const isJumpingFromDeepRef = useRef(false);

	const {
		handleDirectMessageSocketEvent,
		newMessages,
		setNewMessages,
		setHistoryMessages,
		historyMessages,
	} = useHandleDirectMessagesSocketEvents({
		conversationId,
		parentRef,
		updateLastReadTime,
		updateUnreadCount,
		receiverId,
		deleteConversationHandler,
	});

	const resetMessagesStateOnTransition = (conversationId: number) => {
		queryClient.resetQueries({
			queryKey: [MessengerQueryKeys.GET_DIRECT_MESSAGES, conversationId],
		});
		setTransitionLoading(true);
		currentFetchDirectionRef.current = 'next';
		isJumpingFromDeepRef.current = true;
		setNewMessages([]);
		setHistoryMessages([]);
		return;
	};

	const handleNewMessageEventFromDeep = (message: Message) => {
		const newMessageData: {
			Value: NewMessageDataEventResponse;
		} = JSON.parse(message.data);

		const { SenderId, ConversationId } = newMessageData.Value;
		if (
			!conversationId ||
			Number(conversationId) !== ConversationId ||
			SenderId !== user?.userId
		) {
			return;
		}
		resetMessagesStateOnTransition(+conversationId);
	};

	useChannel(
		{
			channelName: `private-user:${user?.userId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			const isNewPrivateMessageEvent =
				message.name === SOCKET_EVENTS.PRIVATE_MESSAGE_EVENT;

			if (conversationId && isNewPrivateMessageEvent && hasPreviousPage) {
				handleNewMessageEventFromDeep(message);
				return;
			}
			if (
				!conversationId ||
				(isNewPrivateMessageEvent && hasPreviousPage)
			) {
				return;
			}

			handleDirectMessageSocketEvent(message);
		},
	);

	const { data: findData } = useFindConversationMessage(
		targetMessageId,
		+(conversationId || 0),
	);
	const {
		data,
		fetchNextPage,
		fetchPreviousPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		hasPreviousPage,
		isFetchingPreviousPage,
	} = useGetDirectMessages(conversationId ? +conversationId : null);

	const onEditMessage = async (messageId: number, newText: string) => {
		try {
			if (!conversationId) {
				return;
			}
			const res = await updatePrivateMessage({
				conversationId: +conversationId,
				privateMessageId: messageId,
				text: newText,
			});
			if (!res?.success) {
				return;
			}
		} catch (error) {
			console.error('Error editing document: ', error);
		}
	};

	const onDeleteMessage = async (messageId: number) => {
		try {
			if (!conversationId) {
				return;
			}
			const res = await deletePrivateMessage({
				conversationId: +conversationId,
				privateMessageId: messageId,
			});
			if (res?.success) {
				handlePrivateMessageDelete({
					messageId,
					conversationId: +conversationId,
				});
			}
		} catch (error) {
			console.error('Error deleting document: ', error);
		}
	};

	const updateMessagesOnReactionSubmit = (
		messageId: number,
		emojiId: MessageReactionType,
		actionType: MessageReactionActionType,
	) => {
		setNewMessages(prev =>
			prev.map(message => {
				if (message.id !== messageId) {
					return message;
				}
				const reactions = updateDirectUserSelfReactions(
					message.reactions,
					emojiId,
					actionType,
				);
				return {
					...message,
					reactions,
				};
			}),
		);

		setHistoryMessages(prev =>
			prev.map(message => {
				if (message.id !== messageId) {
					return message;
				}
				const reactions = updateDirectUserSelfReactions(
					message.reactions,
					emojiId,
					actionType,
				);
				return {
					...message,
					reactions,
				};
			}),
		);
	};

	const onReact = async (
		conversationId: number,
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => {
		try {
			if (!reactedEmojiId) {
				const res = await createDirectMessageReaction({
					conversationId,
					messageId,
					reactionEmojiId: emojiId,
				});
				if (!res.success) {
					return;
				}

				updateMessagesOnReactionSubmit(
					messageId,
					emojiId,
					MessageReactionActionType.CREATE,
				);
				updateMessageReactionsList(emojiId, messageId, 'add');

				return;
			}
			if (emojiId === reactedEmojiId) {
				const res = await removeDirectMessageReaction({
					conversationId,
					messageId,
				});
				if (!res.success) {
					return;
				}

				updateMessagesOnReactionSubmit(
					messageId,
					emojiId,
					MessageReactionActionType.DELETE,
				);

				updateMessageReactionsList(emojiId, messageId, 'remove');

				return;
			}
			const res = await updateDirectMessageReaction({
				conversationId,
				messageId,
				reactionEmojiId: emojiId,
			});
			if (!res.success) {
				return;
			}

			updateMessagesOnReactionSubmit(
				messageId,
				emojiId,
				MessageReactionActionType.EDIT,
			);
		} catch (error) {
			console.log('onReact error: ', error);
		}
	};

	//* extract in hook
	const handleViewPositionOnScrollDown = (
		isFetchingPreviousPage: boolean,
	) => {
		const parentContainer = parentRef.current;

		if (
			isFetchingPreviousPage ||
			!parentContainer ||
			currentFetchDirectionRef.current !== 'prev'
		) {
			return;
		}

		parentRef.current.scrollTo({
			top: -1,
			behavior: 'smooth',
		});
	};

	const combinedMessages = useMemo(
		() => [...newMessages, ...historyMessages],
		[newMessages, historyMessages],
	);

	useEffect(() => {
		if (bottomInView.inView && !isFetchingPreviousPage && hasPreviousPage) {
			currentFetchDirectionRef.current = 'prev';
			fetchPreviousPage();
		}
	}, [bottomInView.inView]);

	useEffect(() => {
		if (topInView.inView && !isFetchingNextPage && hasNextPage) {
			currentFetchDirectionRef.current = 'next';
			fetchNextPage();
		}
	}, [topInView.inView]);

	useEffect(() => {
		if (conversationId && data?.pages.length && !isFetching) {
			const flatMessages =
				data?.pages?.flatMap(page => page?.messages || []) || [];
			setHistoryMessages(flatMessages);
			handleViewPositionOnScrollDown(isFetchingPreviousPage);
			updateLastReadTime(
				data?.pages?.[data?.pages.length - 1].userLastReadDateTime ||
					DEFAULT_LAST_READ_TIME,
			);
			updateUnreadCount(
				data?.pages?.[data?.pages.length - 1].unreadCount || 0,
			);

			if (parentRef.current && isJumpingFromDeepRef.current) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
				setTransitionLoading(false);
				parentRef.current.scrollTo({
					top: -1,
					behavior: 'smooth',
				});
				isJumpingFromDeepRef.current = false;
			}
		}
	}, [data, conversationId, isFetching]);

	useEffect(() => {
		if (findData?.value?.messages?.length) {
			const targetMessage = findData?.value?.messages.find(el => {
				const searchedMessageId = targetMessageId
					? +targetMessageId
					: 0;

				return el.id === searchedMessageId;
			});
			if (!targetMessage) {
				setFindLoading(false);
				setForcedLoading(false);
				const newSearchParams = new URLSearchParams(searchParams);
				newSearchParams.delete('targetMessageId');
				setSearchParams(newSearchParams);
				Alerter.error('Message not found');
				return;
			}

			queryClient.setQueryData(
				[
					MessengerQueryKeys.GET_DIRECT_MESSAGES,
					+(conversationId || 0),
				],
				() => {
					const reversedMessages = [
						...findData.value.messages,
					].reverse();

					currentFetchDirectionRef.current = 'center';
					return {
						pages: [
							{
								...findData.value,
								messages: reversedMessages,
								lastMessageId: reversedMessages.at(-1)?.id,
								firstMessageId: reversedMessages.at(0)?.id,
								direction: 'center',
							},
						],

						pageParams: [
							{
								direction: 'center',
								value: targetMessageId
									? +targetMessageId
									: null,
							},
						],
					};
				},
			);
			setNewMessages([]);
		}
	}, [findData, queryClient]);

	const onNavigateToMessage = async (messageId: number) => {
		const possibleMessageIndex = combinedMessages.findIndex(
			elem => elem.id === messageId,
		);
		if (possibleMessageIndex > -1) {
			const container = parentRef.current;
			const item = container?.children[possibleMessageIndex + 1]; //* +1 to index based on ref on the edges of the list
			if (forcedLoading) {
				setForcedLoading(false);
			}
			if (item) {
				// const itemYPosition = item.getBoundingClientRect().y;
				item.scrollIntoView({
					// behavior:
					// 	itemYPosition >= -1000 && itemYPosition <= 1000
					// 		? 'smooth'
					// 		: 'auto',
					block: 'center',
				});
				await wait(200);
				setHighlightedId(messageId);
				await wait(1000);
				setHighlightedId(null);
			}
			return;
		}

		const newSearchParams = new URLSearchParams(searchParams);
		newSearchParams.set('targetMessageId', messageId.toString());
		setSearchParams(newSearchParams);
		setFindLoading(true);
		setJumpToPresentCondition(JumpToPresentConditionEnum.ReplyMessageJump);
	};

	const onNavigateToLastMessage = useCallback(async () => {
		if (!parentRef.current) {
			return;
		}

		setJumpToPresentCondition(JumpToPresentConditionEnum.Loading);
		if (!hasPreviousPage) {
			parentRef.current.scrollTo({
				top: -500,
			});
			parentRef.current.scrollTo({
				top: -1,
				behavior: 'smooth',
			});
			setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
			return;
		}
		if (!conversationId) {
			return;
		}
		resetMessagesStateOnTransition(+conversationId);
	}, [hasPreviousPage]);

	const loadAllAttachments = async () => {
		const imageElements = Array.from(
			document.querySelectorAll('.attachment-image'),
		);

		const imageLoadPromises = imageElements.map(img => {
			// @ts-ignore
			if (img.complete) {
				return Promise.resolve();
			}
			return new Promise(resolve => {
				// @ts-ignore
				img.onload = img.onerror = resolve;
			});
		});

		await Promise.all(imageLoadPromises);
	};

	const highlightMessage = async (messageId: number) => {
		const index = combinedMessages.findIndex(msg => msg.id === messageId);
		if (index !== -1) {
			const container = parentRef.current;
			const item = container?.children[index + 1]; //* +1 to index based on ref on the edges of the list

			if (forcedLoading) {
				setForcedLoading(false);
			}

			if (item) {
				await loadAllAttachments();
				setFindLoading(false);
				item.scrollIntoView({ block: 'center' });
			}
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.delete('targetMessageId');

			setSearchParams(newSearchParams);
			await wait(200);
			setHighlightedId(messageId);
			if (!replyMessage) {
				setJumpToPresentCondition(JumpToPresentConditionEnum.Visible);
			}
			await wait(1000);
			setHighlightedId(null);
		}
	};

	useEffect(() => {
		if (targetMessageId) {
			highlightMessage(+targetMessageId!);
		}
	}, [targetMessageId, combinedMessages]);

	useEffect(() => {
		if (messageAnchorId && conversationId && !!combinedMessages.length) {
			onNavigateToMessage(messageAnchorId);
			setMessageAnchorId(0);
		}
	}, [messageAnchorId, conversationId, combinedMessages]);

	useEffect(() => {
		if (
			jumpToPresentCondition ===
			JumpToPresentConditionEnum.StartConversationJump
		) {
			onNavigateToLastMessage();
		}
	}, [jumpToPresentCondition]);

	//* extract in hook
	const onScrollContainer = useCallback(() => {
		const currentScrollPosition = parentRef.current?.scrollTop || 0;
		const { Hidden, Visible, Loading } = JumpToPresentConditionEnum;

		const isInCenterFetch = currentFetchDirectionRef.current === 'center';
		const isScrolledNearTop =
			currentScrollPosition >= -1500 && currentScrollPosition < 0;
		const isVisibleCondition =
			jumpToPresentCondition === Visible ||
			jumpToPresentCondition === Loading;
		const isReplying = !!replyMessage;

		if (
			isInCenterFetch &&
			!isReplying &&
			jumpToPresentCondition === Hidden
		) {
			setJumpToPresentCondition(Visible);
			return;
		}

		if (
			isScrolledNearTop &&
			!hasPreviousPage &&
			jumpToPresentCondition !== Hidden
		) {
			setJumpToPresentCondition(Hidden);
			return;
		}

		if (
			isInCenterFetch ||
			currentScrollPosition > -1500 ||
			isVisibleCondition ||
			isReplying
		) {
			return;
		}

		setJumpToPresentCondition(Visible);
	}, [jumpToPresentCondition, replyMessage, hasPreviousPage]);

	const getContainerHeight = () => {
		const offset =
			jumpToPresentCondition === JumpToPresentConditionEnum.Visible ||
			jumpToPresentCondition === JumpToPresentConditionEnum.Loading ||
			replyMessage
				? 230
				: 160;

		return `calc(100vh - ${offset}px)`;
	};
	const isLoadingMessages = (
		isFetching: boolean,
		isFetchingNextPage: boolean,
		isFetchingPreviousPage: boolean,
	) => isFetching && !isFetchingNextPage && !isFetchingPreviousPage;

	const isLoadingReceiver = (
		receiverLoading: boolean,
		receiver: UserByIdData | null,
	) => receiverLoading && !receiver;

	const isHistoryStillLoading = (
		dataMessages: ConversationHistoryItem[],
		historyMessages: ConversationHistoryItem[],
	) => dataMessages.length !== 0 && historyMessages.length === 0;

	const conversationLoading =
		transitionLoading ||
		isLoadingMessages(
			isFetching,
			isFetchingNextPage,
			isFetchingPreviousPage,
		) ||
		isLoadingReceiver(receiverLoading, receiver) ||
		isHistoryStillLoading(
			data?.pages?.[0]?.messages || [],
			historyMessages,
		);

	const startOfDirectChatLoading =
		!transitionLoading &&
		!isFetching &&
		!isFetchingNextPage &&
		!isFetchingPreviousPage &&
		!receiverLoading;

	const setRefs = useCallback(
		(node: HTMLDivElement) => {
			// Callback refs, like the one from `useInView`, is a function that takes the node as an argument
			bottomInView.ref(node);
		},
		[bottomInView],
	);
	return (
		<>
			<Flex
				ref={parentRef}
				flexDirection="column-reverse"
				position="relative"
				pt="40px"
				h={getContainerHeight()}
				transition="height .2s ease"
				overflowY="auto"
				onScroll={onScrollContainer}>
				{conversationLoading ? (
					<Loader centerHeight="100%" />
				) : (
					<>
						<Box ref={setRefs} />
						{isFetchingPreviousPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						{combinedMessages.map((elem, index) => (
							<div
								key={elem.id}
								className={
									elem?.id === highlightedId
										? 'highlight'
										: ''
								}>
								<MessageWithAddons
									key={elem.id}
									arr={combinedMessages}
									data={elem}
									index={index}
									onEditMessage={onEditMessage}
									onSetDeleteMessage={setMessageToDelete}
									onSetReplyMessage={onSetReplyMessage}
									allowFullAccess={
										user?.userId === elem.senderId
									}
									includeEdit={user?.userId === elem.senderId}
									receiver={
										user?.userId === elem.senderId
											? null
											: receiver
									}
									includePin={false}
									disableMenu={
										receiver?.isBlockingMe ||
										receiver?.isBlocked
									}
									enableMentions={false}
									messageType={elem.type}
									chatType={ChatType.DIRECT}
									includeUserInfoPopup={true}
									lastReadTime={
										lastReadTime === DEFAULT_LAST_READ_TIME
											? undefined
											: lastReadTime
									}
									onReact={(
										messageId,
										emojiId,
										reactedEmojiId,
									) => {
										if (conversationId) {
											onReact(
												+conversationId,
												messageId,
												emojiId,
												reactedEmojiId,
											);
										}
									}}
									onReplyPress={onNavigateToMessage}
									isReplyMessageJumpEnabled={true}
									isChatListFlow={true}
								/>
							</div>
						))}
						{combinedMessages?.length ? (
							<DateDivider
								date={
									new Date(
										combinedMessages[
											combinedMessages.length - 1
										].sentAt,
									)
								}
							/>
						) : null}
						{isFetchingNextPage ? (
							<Loader centerHeight="80px" spinnerSize="sm" />
						) : null}
						<Box ref={topInView.ref} />
						{startOfDirectChatLoading ? (
							<StartOfDirectChat
								receiver={receiver}
								receiverId={receiverId}
							/>
						) : null}
					</>
				)}
			</Flex>
			<DeleteMessageConfirmModal
				isOpen={!!messageToDelete}
				onClose={() => setMessageToDelete(null)}
				onDeleteMessage={onDeleteMessage}
				messageToDelete={messageToDelete}
				chatType={ChatType.DIRECT}
			/>
		</>
	);
};

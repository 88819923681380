import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { MessengerQueryKeys } from '../../queries/query-keys';
import { ConversationHistoryItem } from '../../types';

export const useHandlePrivateMessageDeletion = () => {
	const queryClient = useQueryClient();

	const handlePrivateMessageDelete = (data: {
		conversationId: number;
		messageId: number;
	}) => {
		queryClient.setQueryData<
			InfiniteData<{ messages: ConversationHistoryItem[] }> | undefined
		>(
			[MessengerQueryKeys.GET_DIRECT_MESSAGES, data.conversationId],
			oldData => {
				if (oldData?.pages.length) {
					return {
						...oldData,
						pages: oldData.pages.map(page => {
							const filteredMessages = page.messages.filter(
								elem => elem.id !== data.messageId,
							);
							return {
								...page,
								messages: filteredMessages,
							};
						}),
					};
				}
				return oldData;
			},
		);
	};

	return handlePrivateMessageDelete;
};

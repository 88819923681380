import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { Message } from 'ably';

import { SOCKET_EVENTS } from '../../constants';
import {
	ConversationHistoryItem,
	DeletePrivateMessageEventResponse,
	DirectMessageReactionMainEvent,
	DirectMessageReactionUpdateEvent,
	EditPrivateMessageEventResponse,
	JumpToPresentConditionEnum,
	NewMessageDataEventResponse,
} from '../../types';
import {
	markdownWithoutTags,
	updateMessagesOnDelete,
	updateReactionsOnCreate,
	updateReactionsOnDelete,
	updateReactionsOnEdit,
} from '../../utils';
import {
	useChatUserStore,
	useConversationsList,
	useMessageNavigationStore,
} from '../../../../store';
import { Alerter } from '../../../../utils';
import { useUpdateUserInfoById } from '../../hooks';

export const useHandleDirectMessagesSocketEvents = ({
	parentRef,
	updateLastReadTime,
	updateUnreadCount,
	receiverId,
	conversationId,
	deleteConversationHandler,
}: {
	conversationId?: string | null;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	updateLastReadTime: (time: string) => void;
	parentRef: React.RefObject<HTMLDivElement>;
	receiverId?: string;
	deleteConversationHandler: VoidFunction;
}) => {
	const { updateConversationsList, updateConversationLastMessage } =
		useConversationsList();
	const { user } = useChatUserStore();
	const { setJumpToPresentCondition } = useMessageNavigationStore();
	const { mutate: updateUserInfoById } = useUpdateUserInfoById();

	const [newMessages, setNewMessages] = useState<ConversationHistoryItem[]>(
		[],
	);
	const [historyMessages, setHistoryMessages] = useState<
		ConversationHistoryItem[]
	>([]);

	const handleDirectMessageSocketEvent = useCallback(
		(event: Message) => {
			switch (event.name) {
				case SOCKET_EVENTS.PRIVATE_MESSAGE_EVENT:
					{
						const newMessageData: {
							Value: NewMessageDataEventResponse;
						} = JSON.parse(event.data);
						const {
							Text,
							SentAt,
							SenderId,
							ReplyToId,
							MessageId,
							DisplayName,
							ConversationId,
							AvatarUrl,
							IsAdmin,
							Type,
							PinToId,
							AttachmentImages,
							AttachmentFiles,
							AttachmentVideos,
						} = newMessageData.Value;

						const isCurrentUserSender = SenderId === user?.userId;
						updateConversationsList(
							isCurrentUserSender
								? Number(receiverId) || 0
								: SenderId,
							{
								lastMessage: {
									id: MessageId,
									senderId: SenderId,
									text: Text
										? markdownWithoutTags(Text)
										: null,
								},
							},
						);

						if (
							conversationId &&
							+conversationId !== ConversationId
						) {
							return;
						}

						const messagePayload = {
							id: MessageId,
							replyToId: ReplyToId,
							text: Text,
							isEdited: false,
							isSeen: isCurrentUserSender,
							senderId: SenderId,
							senderName: DisplayName,
							sentAt: SentAt,
							avatarUrl: AvatarUrl,
							type: Type,
							isAdmin: IsAdmin,
							pinToId: PinToId,
							isSenderDeleted: false,
							attachmentFiles: AttachmentFiles.map(attachment => {
								return {
									fileName: attachment.FileName,
									id: attachment.Id,
									extension: attachment.Extension,
									link: attachment.Link,
									storagePath: attachment.StoragePath,
								};
							}),
							attachmentImages: AttachmentImages.map(
								attachment => {
									return {
										id: attachment.Id,
										extension: attachment.Extension,
										link: attachment.Link,
										storagePath: attachment.StoragePath,
										thumbnailLink: attachment.ThumbnailLink,
										largeImageLink:
											attachment.LargeImageLink,
									};
								},
							),
							attachmentVideos: AttachmentVideos.map(
								attachment => ({
									id: attachment.Id,
									link: attachment.Link,
									storagePath: attachment.StoragePath,
									duration: attachment.Duration,
									fileName: attachment.FileName,
									extension: attachment.Extension,
									height: attachment.Height,
									width: attachment.Width,
								}),
							),
							reactions: [],
						};

						if (isCurrentUserSender) {
							updateUnreadCount(0);
							updateLastReadTime(SentAt);
						}

						setNewMessages(prev => [
							{ ...messagePayload },
							...(prev as ConversationHistoryItem[]),
						]);
						if (!isCurrentUserSender) {
							updateUnreadCount(prev => prev + 1);
							return;
						}
						setJumpToPresentCondition(
							JumpToPresentConditionEnum.Hidden,
						);
						parentRef.current?.scrollTo({ top: 0 });
					}
					break;
				case SOCKET_EVENTS.EDIT_MESSAGE_EVENT:
					{
						const editedMessageData: {
							Value: EditPrivateMessageEventResponse;
						} = JSON.parse(event.data);
						const { Text, ConversationId, UpdatedMessageId } =
							editedMessageData.Value;

						updateConversationLastMessage(
							ConversationId,
							{
								id: UpdatedMessageId,
								text: Text ? markdownWithoutTags(Text) : null,
							},
							false,
						);

						if (
							conversationId &&
							+conversationId !== ConversationId
						) {
							return;
						}

						const updatedMessagePayload = {
							text: Text,
							isEdited: true,
						};

						setNewMessages(prev =>
							prev.map(msg =>
								msg.id === UpdatedMessageId
									? { ...msg, ...updatedMessagePayload }
									: msg,
							),
						);
						setHistoryMessages(prev =>
							prev.map(msg =>
								msg.id === UpdatedMessageId
									? { ...msg, ...updatedMessagePayload }
									: msg,
							),
						);
					}
					break;
				case SOCKET_EVENTS.DELETE_MESSAGE_EVENT:
					{
						const deletedMessageData: {
							Value: DeletePrivateMessageEventResponse;
						} = JSON.parse(event.data);

						const { ConversationId, DeletedMessageId } =
							deletedMessageData.Value;

						updateConversationLastMessage(
							ConversationId,
							{
								id: DeletedMessageId,
								text: null,
							},
							true,
						);

						if (
							conversationId &&
							+conversationId !== ConversationId
						) {
							return;
						}

						setNewMessages(prev =>
							updateMessagesOnDelete(prev, DeletedMessageId),
						);
						setHistoryMessages(prev =>
							updateMessagesOnDelete(prev, DeletedMessageId),
						);
					}
					break;
				case SOCKET_EVENTS.PRIVATE_MESSAGE_REACTION_CREATE:
					{
						const newReactionData: {
							Value: DirectMessageReactionMainEvent;
						} = JSON.parse(event.data);
						const {
							ConversationId,
							Count,
							EmojiId,
							MessageId,
							UserId,
						} = newReactionData.Value;

						if (Number(conversationId) !== ConversationId) {
							return;
						}

						setNewMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnCreate(
									message.reactions,
									EmojiId,
									Count,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);

						setHistoryMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnCreate(
									message.reactions,
									EmojiId,
									Count,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);
					}
					break;
				case SOCKET_EVENTS.PRIVATE_MESSAGE_REACTION_UPDATE:
					{
						const editReactionData: {
							Value: DirectMessageReactionUpdateEvent;
						} = JSON.parse(event.data);
						const {
							ConversationId,
							DeleteReaction,
							CreateReaction,
							MessageId,
							UserId,
						} = editReactionData.Value;

						if (Number(conversationId) !== ConversationId) {
							return;
						}

						setNewMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnEdit(
									message.reactions,
									CreateReaction,
									DeleteReaction,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);
						setHistoryMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnEdit(
									message.reactions,
									CreateReaction,
									DeleteReaction,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);
					}
					break;
				case SOCKET_EVENTS.PRIVATE_MESSAGE_REACTION_DELETE:
					{
						const removeReactionData: {
							Value: DirectMessageReactionMainEvent;
						} = JSON.parse(event.data);
						const {
							ConversationId,
							Count,
							EmojiId,
							MessageId,
							UserId,
						} = removeReactionData.Value;

						if (Number(conversationId) !== ConversationId) {
							return;
						}
						setNewMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnDelete(
									message.reactions,
									EmojiId,
									Count,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);

						setHistoryMessages(prev =>
							prev.map(message => {
								if (message.id !== MessageId) {
									return message;
								}
								const reactions = updateReactionsOnDelete(
									message.reactions,
									EmojiId,
									Count,
									UserId,
									user?.userId,
								);
								return {
									...message,
									reactions,
								};
							}),
						);
					}
					break;
				case SOCKET_EVENTS.DELETE_CONVERSATION_EVENT:
					{
						const removeConversationData: {
							Value: { DeletedConversationId: number };
						} = JSON.parse(event.data);
						const { DeletedConversationId } =
							removeConversationData.Value;

						if (DeletedConversationId !== Number(conversationId)) {
							return;
						}
						Alerter.info(
							'This conversation has been deleted, but you can start a new one',
						);
						setNewMessages([]);
						setHistoryMessages([]);
						deleteConversationHandler();
					}
					break;
				//handle block status by another user
				case SOCKET_EVENTS.FRIEND_DELETED_EVENT:
					{
						const deletedFriendRequest: {
							Value: {
								InitiatorUserId: number;
								DeletedUserId: number;
							};
						} = JSON.parse(event.data);
						const { DeletedUserId } = deletedFriendRequest.Value;
						if (DeletedUserId !== user?.userId || !receiverId) {
							return;
						}
						updateUserInfoById(+receiverId, { isFriend: false });
					}
					break;
				//handle unblock status by another user
				case SOCKET_EVENTS.USER_UNBLOCKED_EVENT:
					{
						const unblockedUser: {
							Value: { UserId: number };
						} = JSON.parse(event.data);
						const { UserId } = unblockedUser.Value;
						if (Number(receiverId) !== UserId || !receiverId) {
							return;
						}
						updateUserInfoById(+receiverId, {
							isBlocked: false,
							isBlockingMe: false,
						});
					}
					break;
				default:
					break;
			}
		},
		[conversationId],
	);

	return {
		handleDirectMessageSocketEvent,
		newMessages,
		setNewMessages,
		historyMessages,
		setHistoryMessages,
	};
};

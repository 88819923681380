import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DEAL_EXPLORER_FILTERS_KEY } from '../constants';
import { DealExplorerFilterState } from '../pages/DealExplorer/types';
import { userManager } from '../utils';

interface IState {
	data: DealExplorerFilterState;
}

interface IActions {
	persistFiltersState: (updates: Partial<DealExplorerFilterState>) => void;
	resetFiltersState: VoidFunction;
	setInitialState: () => Promise<void>;
}

// const isGuest = useDefaultUserStore.getState().user?.roleId === Role.Guest;

const initialGuestState = {
	state: null,
	county: null,
	city: null,
	propertyStatus: 1,
	minPrice: '90000',
	maxPrice: '120000',
	minCashFlow: '700',
	sellerFinancingChecked: false,
	showFavourite: false,
	bedrooms: ['-1'],
};

export const initialDealExplorerFilersState: IState = {
	data: {
		state: null,
		county: null,
		city: null,
		propertyStatus: 1,
		// minPrice: isGuest ? '90000' : '',
		// maxPrice: isGuest ? '120000' : '150000',
		// minCashFlow: isGuest ? '700' : '250',
		minPrice: '55000',
		maxPrice: '150000',
		minCashFlow: '250',
		sellerFinancingChecked: false,
		showFavourite: false,
		bedrooms: ['-1'],
	},
};

export const usePersistDealExplorerStore = create(
	persist<IState & IActions>(
		set => ({
			...initialDealExplorerFilersState,
			persistFiltersState: updates =>
				set(state => ({
					...state,
					data: { ...state.data, ...updates },
				})),
			resetFiltersState: () => {
				set(initialDealExplorerFilersState);
			},
			setInitialState: async () => {
				try {
					const data = await userManager.getUser();
					if (data?.profile?.role === 'guest') {
						set(() => ({
							data: { ...initialGuestState },
						}));
					}
				} catch (err) {
					console.log(err);
					set(initialDealExplorerFilersState);
				}
			},
		}),
		{
			name: DEAL_EXPLORER_FILTERS_KEY,
			version: 1,
		},
	),
);
